<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="搜索品类名称" class="search-input" clearable v-model="searchObj.classifyName"></el-input>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="getTypeData">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="openDialog('add')">创建品类</el-button>
            </div>
            <!-- <div class="flx-row ali-c">
              <el-button icon="el-icon-download" type="primary" plain>导入</el-button>
              <el-button icon="el-icon-upload2" class="ml-15" type="primary" plain>导出</el-button>
            </div>-->
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="品类名称" prop="classifyName"></el-table-column>
            <el-table-column label="优惠券数量(已上架)" prop="couponNum"></el-table-column>
            <el-table-column label="添加时间" prop="createTime"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <!-- <router-link :to="{name: 'Coupons_list', query:{classifyId: scope.row.id}}">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-info"></i>
                      查看
                    </el-link>
                  </router-link> -->
                  <span @click="openDialog('edit', scope.row)" class="tip-text-edit">
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <!-- 轻量型弹窗确认 -->
                  <el-popconfirm @onConfirm="deleteType(scope.row.classifyId)" title="确认删除吗？">
                    <el-link slot="reference" type="danger" :underline="false" class="ml-10">
                      <i class="el-icon-delete-solid"></i>
                      删除
                    </el-link>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="getTypeData"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 创建新品类弹窗 -->
    <el-dialog title="添加分类" :visible.sync="dialogVisible" width="30%" @close='clearDialog'>
      <el-form :model="cate_form" :rules="rules" ref="cate_form" label-width="100px">
        <el-form-item label="分类名称" prop="classifyName">
          <el-input placeholder="输入品类名称" v-model="cate_form.classifyName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import {deleteCouponClassify, getCouponClassifyList, addCouponClassify } from "@/api/maketing/coupons"
export default {
  name: "coupons_type",
  components: {
    Pagination
  },
  data() {
    return {
      // 创建新品类的弹窗
      dialogVisible: false,
      // 顶部搜索栏数据
      searchObj: {
        classifyName: ""
      },
      cate_form: {
        classifyName: ""
      },
      rules: {
        classifyName: [{ required: true, message: "分类名称不能为空", trigger: "blur" }]
      },
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      total: 0
    };
  },
  created() {
    this.getTypeData()
  },
  methods: {
    //获取优惠券分类列表
    getTypeData(type) {
      if(type == 1){
          this.pageNum = 1
          this.pageSize = 10
      }
      let data = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        classifyName: this.searchObj.classifyName
      }
      getCouponClassifyList(data).then(res => {
          this.total = res.data.body.total
          this.tableData = res.data.body.list
      })
    },
    clearDialog(){ //清除弹窗数据
      this.cate_form =  {
        classifyName: ""
      }
    },
    openDialog(type, row){ //打开添加、编辑分类弹窗
      if(type == 'edit'){
        this.cate_form = JSON.parse(JSON.stringify(row))
      }
      this.dialogVisible = true
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    submit() { //提交分类验证
      this.$refs['cate_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.submitForm()
      })
    },
    submitForm() { //提交分类请求
      let data = JSON.parse(JSON.stringify(this.cate_form))
      if(data.classifyId){
        data = {
          id: data.classifyId,
          classifyName: data.classifyName
        }
      }
       
      addCouponClassify(data).then(res => {
        if(res.data.code == 200){
          this.dialogVisible = false
          this.getTypeData()
          this.clearDialog()
          this.$message({
            message: "操作成功",
            type: "success"
          })
        }
      })
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        classifyName: "",
      };
      this.getTypeData(1)
    },
    // 删除品类
    deleteType(id) {
      deleteCouponClassify(id).then(res => {
        this.getTypeData()
        this.$message({
          message: "删除成功",
          type: "success"
        })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
